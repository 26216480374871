<template>
  <v-expansion-panels
    class="filters-expansion"
    :multiple="multiple"
    tile
  >
    <v-expansion-panel
      v-for="( item, index ) in items"
      :readonly="!item[itemChildren] || !item[itemChildren].length"
      :key="index"
    >
      <v-expansion-panel-header
        class="pa-1"
        :hide-actions="!item[itemChildren] || !item[itemChildren].length"
      >

        <chip-base
          class="shrink"
          :item="item"
          :item-value="itemValue"
          :item-text="itemText"
          :item-image="itemImage"
          :item-image-src="itemImageSrc"
          :base-url="itemBaseUrl"
          :small="small"
          :link="link"
          image
        />

      </v-expansion-panel-header>
      <v-expansion-panel-content>

        <chip-base
          class="ma-1"
          v-for="( subitem, e ) in (item[itemChildren] || [])"
          :key="e"
          :item="subitem"
          :item-value="subItemValue"
          :item-text="subItemText"
          :item-image="subItemImage"
          :item-image-src="subItemImageSrc"
          :base-url="subItemBaseUrl"
          :link="link"
          color="grey lighten-4"
          small image
        />

      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import ChipBase from './ChipBase';
export default {
  components: { ChipBase },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemValue: {
      type: String,
      default: 'id'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    itemImage: {
      type: String,
      default: 'picture'
    },
    itemImageSrc: {
      type: String,
      default: 'thumbnailUrl'
    },
    itemBaseUrl: String,
    itemChildren: {
      type: String,
      default: 'filterItems'
    },
    subItemValue: {
      type: String,
      default: 'id'
    },
    subItemText: {
      type: String,
      default: 'name'
    },
    subItemImage: {
      type: String,
      default: 'picture'
    },
    subItemImageSrc: {
      type: String,
      default: 'thumbnailUrl'
    },
    subItemBaseUrl: String,
    multiple: Boolean,
    link: Boolean,
    small: Boolean
  }
}
</script>

<style>
.filters-expansion .v-expansion-panel::before {
  box-shadow: none;
}
.filters-expansion .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 48px;
}
.filters-expansion .v-expansion-panel-content__wrap {
  padding: 0 8px 8px;
}
.filters-expansion .v-expansion-panel--active:not(:first-child), .v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0;
}
</style>

<template>
  <v-form
    ref="form"
    @submit.prevent="onSave"
  >

    <image-field
      v-model="model.picture"
      :rules="rules.picture"
      height="200"
      clearable
      contain
    />

    <div class="pa-2">

      <text-field
        v-model="model.name"
        :rules="rules.name"
        :label="$t('inputs.name.label')"
      />

      <select-field
        v-model="model.status"
        :label="$t('inputs.status.label')"
        :items="categoryStatus"
      />

      <number-field
        v-model="model.position"
        :label="$t('inputs.position.label')"
      />

      <template v-if="!isNew">

        <h3 class="title black--text mb-3">
          {{ $t('inputs.filters.label') }}
        </h3>

        <expansion-panel-base
          class="ma-n1"
          v-if="filters.length"
          :items="filters"
          item-children="filterItems"
          item-base-url="/category-filter"
          sub-item-base-url="/category-filter-item"
          multiple
          link
        />

        <div v-else>
          {{ $t('category.emptyFilters') }}
        </div>

      </template>
    </div>
  </v-form>
</template>

<script>
import TextField from '@/components/form/TextField';
import SelectField from '@/components/form/SelectField';
import ImageField from '@/components/form/ImageField';
import NumberField from '@/components/form/NumberField';
import ExpansionPanelBase from '@/components/values/ExpansionPanelBase';
import { CATEGORY_STATUS } from '@/utils/constants';
import { required } from '@/utils/rules';

export default {
  components: {
    TextField,
    SelectField,
    ImageField,
    NumberField,
    ExpansionPanelBase
  },
  props: {
    value: Object,
    isNew: Boolean,
    save: Function
  },
  data() {
    return {
      model: this.value || {}
    }
  },
  computed: {
    categoryStatus() {
      return Object.values( CATEGORY_STATUS ).map( v => ({
        value: Number(v),
        text: this.$t('category.status.' + v )
      }))
    },
    rules() {
      return {
        name: [
          required( this.$t('inputs.name.rules.required'))
        ]
      };
    },
    filters() {
      return ( this.model.filters || [] ).filter( i => i.status !== -1 )
    }
  },
  watch: {
    value( model ) {
      this.model = model || {};
    }
  },
  methods: {
    onSave() {
      this.$emit( 'input', this.model );
      this.save && this.$nextTick( this.save );
    },
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    }
  }
}
</script>
